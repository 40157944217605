
// const url = `http://localhost:5001/api/v1`;
const url = `https://api.masarati.ai/api/v1`;

export const environment = {

  production: false,
  // api_url: 'http://localhost:5001/api/v1',

  // https://api.masarati.ai
  api_url: url,
  // img_url: 'https://api.masarati.ai/uploads/',
  img_url: url + '/uploads/',

};
