<h1 id="title" class="title">Forgot Password</h1>
<p class="sub-title">Enter email and we'll send you a link to reset your password</p>

<form [formGroup]="dynamicForm" (ngSubmit)="submitForm()" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Email address:</label>
    <input nbInput fullWidth type="email" formControlName="email_id" placeholder="Enter email address">
  </div>

  <!-- <div class="form-control-group forgot-password">
    Resend Email?
  </div> -->

  <button nbButton fullWidth *ngIf="isLoading" type="button" nbButton status="primary" class="mr-3">
    <i class="fa fa-spinner fa-spin"></i>
  </button>
  <button nbButton fullWidth [disabled]="!dynamicForm.valid" *ngIf="!isLoading" type="submit" nbButton status="primary"
    class="mr-3">
    Send Reset Link
  </button>
</form>
