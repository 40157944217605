import { Component } from '@angular/core';
import { AdminConstant } from '../../../helper/constant';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { AuthService } from '../../../helper/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FE_route } from '../../../helper/api_endpoints';


// Define a custom password validator function
function passwordValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.value;

  if (!password) return null;

  const minLength = 8;
  const maxLength = 16;
  const hasDigit = /\d/.test(password);
  const hasAlphabet = /[a-zA-Z]/.test(password);
  const hasSpecialChar = /[!@#\$%\^&\*]/.test(password); // allow only a few special characters

  if (password.length < minLength || password.length > maxLength) {
    return { passwordLength: `Password must be between ${minLength} and ${maxLength} characters.` };
  }
  if (!hasDigit) {
    return { passwordDigit: 'Password must contain at least one digit.' };
  }
  if (!hasAlphabet) {
    return { passwordAlphabet: 'Password must contain at least one alphabet.' };
  }
  if (!hasSpecialChar) {
    return { passwordSpecialChar: 'Password must contain at least one special character: ! @ # $ % ^ & *' };
  }

  return null; // Valid password
}

// Define a custom validator to check if the new password and confirm password match
function matchPasswords(control: AbstractControl): ValidationErrors | null {
  const newPassword = control.get('new_password')?.value;
  const confirmPassword = control.get('confirm_password')?.value;

  if (newPassword && confirmPassword && newPassword !== confirmPassword) {
    return { passwordsMismatch: 'New password and confirm password do not match.' };
  }

  return null;
}

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  currentTheme = AdminConstant.currentTheme;
  isLoading: boolean = false;
  dynamicForm: FormGroup;
  token: string;

  constructor(
    private fb: FormBuilder,
    private themeService: NbThemeService,
    private authService: AuthService,
    private toastrService: NbToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.token = activatedRoute.snapshot.params.token;
  }

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.dynamicForm.controls;
  }
  loadData() {
    // this.dynamicForm = this.fb.group({
    //   new_password: ['', [Validators.required]],
    //   confirm_password: ['', [Validators.required]],
    // })
    this.dynamicForm = this.fb.group(
      {
        new_password: ['', [Validators.required, passwordValidator]],
        confirm_password: ['', [Validators.required]],
      },
      { validators: matchPasswords }
    );
    this.themeService.changeTheme(this.currentTheme);
  }

  submitForm() {
    console.log(this.dynamicForm.value, "Form value");
    this.isLoading = true;
    this.authService.newPassword({
      token: this.token,
      new_password: this.dynamicForm.value.new_password,
      confirm_password: this.dynamicForm.value.confirm_password
    })
      .pipe()
      .subscribe({
        next: (res: any) => {
          this.isLoading = false;
          this.toastrService.success(res?.message, AdminConstant.toastr.success);
          this.router.navigate([FE_route.login]);
        },
        error: (err: any) => {
          console.log(err, "err in sendResetPasswordLink")
          this.toastrService.danger(err?.error?.message, AdminConstant.toastr.error);
          this.isLoading = false;
          this.router.navigate([FE_route.forgot_password]);
        }
      })

  }

  togglePasswordVisibility(element: any) {
    if (element.type === 'password') {
      element.type = 'text';
    } else {
      element.type = 'password';
    }

  }
}


