import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminConstant } from '../../../helper/constant';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { AuthService } from '../../../helper/services/auth.service';
import { Router } from '@angular/router';
import { FE_route } from '../../../helper/api_endpoints';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  currentTheme = AdminConstant.currentTheme;
  isLoading: boolean = false;
  dynamicForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private themeService: NbThemeService,
    private authService: AuthService,
    private toastrService: NbToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.dynamicForm.controls;
  }
  loadData() {
    this.dynamicForm = this.fb.group({
      email_id: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    })
    this.themeService.changeTheme(this.currentTheme);
  }

  submitForm() {
    console.log(this.dynamicForm.value, "Form value");
    this.isLoading = true;
    this.authService.adminUserlogin(this.dynamicForm.value)
      .pipe()
      .subscribe({
        next: (res: any) => {
          localStorage.setItem('auth_token', res?.auth_token);

          let slug = res?.data?.permission_list?.map((item: any) => item.slug);

          this.toastrService.success(res?.message, AdminConstant.toastr.success);
          this.isLoading = false;

          console.log(slug, "slug in login");

          // this.authService.setMenuItems(res?.data?.permission_list);
          if (slug[0].includes('dashboard')) {
            this.router.navigate([FE_route.dashboard]);
            return
          }
          if (slug[0].includes('user_management')) {
            this.router.navigate([FE_route.admin_user_list]);
            return
          }
          if (slug[0].includes('roles_and_permission')) {
            this.router.navigate([FE_route.role_permission_list]);
            return
          }
          if (slug[0].includes('subscription_management')) {
            this.router.navigate([FE_route.subscription_plan_list]);
            return
          }

          this.router.navigate([FE_route.my_account]);
        },
        error: (err: any) => {
          console.log(err, "err in login")
          this.toastrService.danger(err?.error?.message, AdminConstant.toastr.error);
          this.isLoading = false;
        }
      })

  }

  togglePasswordVisibility(element: any) {
    if (element.type === 'password') {
      element.type = 'text';
    } else {
      element.type = 'password';
    }

  }
}
