import { Component } from '@angular/core';
import { NbRequestPasswordComponent } from '@nebular/auth';
import { AdminConstant } from '../../../helper/constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { AuthService } from '../../../helper/services/auth.service';
import { Router } from '@angular/router';
import { FE_route } from '../../../helper/api_endpoints';

@Component({
  selector: 'ngx-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  currentTheme = AdminConstant.currentTheme;
  isLoading: boolean = false;
  dynamicForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private themeService: NbThemeService,
    private authService: AuthService,
    private toastrService: NbToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.dynamicForm.controls;
  }
  loadData() {
    this.dynamicForm = this.fb.group({
      email_id: ['', [Validators.required, Validators.email]],
    })
    this.themeService.changeTheme(this.currentTheme);
  }

  submitForm() {
    console.log(this.dynamicForm.value, "Form value");
    this.isLoading = true;
    this.authService.sendResetPasswordLink(this.dynamicForm.value?.email_id)
      .pipe()
      .subscribe({
        next: (res: any) => {
          this.isLoading = false;
          this.toastrService.success(res?.message, AdminConstant.toastr.success);
          this.router.navigate([FE_route.login]);
        },
        error: (err: any) => {
          console.log(err, "err in sendResetPasswordLink")
          this.toastrService.danger(err?.error?.message, AdminConstant.toastr.error);
          this.isLoading = false;
        }
      })

  }

  togglePasswordVisibility(element: any) {
    if (element.type === 'password') {
      element.type = 'text';
    } else {
      element.type = 'password';
    }

  }
}

