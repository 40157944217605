<h1 id="title" class="title">Login</h1>
<p class="sub-title">Hello! Log in with your email.</p>

<form [formGroup]="dynamicForm" (ngSubmit)="submitForm()" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Email address:</label>
    <input nbInput fullWidth type="email" formControlName="email_id">
  </div>

  <div class="form-control-group position-relative">
    <span class="label-with-link">
      <label class="label" for="input-password">Password:</label>
    </span>
    <input nbInput fullWidth type="password" formControlName="password" #password>

    <span class="passwordEye position-absolute top-50 end-0 px-2 translate-middle-y cursor-pointer"
      (click)="togglePasswordVisibility(password)">
      <svg *ngIf="password.type === 'text'" style="margin-top: -5px; margin-right: 6px;" width="18" height="20"
        viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 0C3.63065 0 0.172888 5.11268 0.0471513 5.32958C-0.0157171 5.42253 -0.0157171 5.57746 0.0471513 5.67042C0.204322 5.88732 3.63065 11 8 11C12.3694 11 15.8271 5.88732 15.9528 5.67042C16.0157 5.57746 16.0157 5.42253 15.9528 5.32958C15.8271 5.11268 12.3694 0 8 0ZM8 10.3493C4.38507 10.3493 1.30452 6.35211 0.675835 5.48451C1.30452 4.6169 4.38507 0.619718 8 0.619718C11.6149 0.619718 14.6955 4.6169 15.3242 5.48451C14.6955 6.35211 11.6149 10.3493 8 10.3493Z"
          fill="#494E55" />
        <path
          d="M7.99997 2.94385C6.58543 2.94385 5.42236 4.09033 5.42236 5.48469C5.42236 6.87906 6.58543 8.02554 7.99997 8.02554C9.4145 8.02554 10.5776 6.87906 10.5776 5.48469C10.5776 4.09033 9.4145 2.94385 7.99997 2.94385ZM7.99997 7.40582C6.9312 7.40582 6.05105 6.53821 6.05105 5.48469C6.05105 4.43117 6.9312 3.56357 7.99997 3.56357C9.06873 3.56357 9.94889 4.43117 9.94889 5.48469C9.94889 6.53821 9.06873 7.40582 7.99997 7.40582Z"
          fill="#494E55" />
      </svg>

      <svg *ngIf="password.type === 'password'" style="margin-top: 0px; margin-right: 6px" width="18" height="20"
        viewBox="0 0 32 40" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
        x="0px" y="0px" enable-background="new 0 0 32 32" xml:space="preserve">
        <path fill="#898b8d"
          d="M25.23,8.184l5.477-5.477c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0L23.55,7.037C21.516,5.835,19.019,5,16,5  C5.444,5,1.245,15.195,1.071,15.628c-0.095,0.239-0.095,0.504,0,0.743c0.109,0.273,1.816,4.414,5.698,7.445l-5.477,5.477  c-0.391,0.391-0.391,1.023,0,1.414C1.488,30.902,1.744,31,2,31s0.512-0.098,0.707-0.293l5.743-5.744C10.484,26.165,12.981,27,16,27  c10.556,0,14.755-10.195,14.929-10.628c0.095-0.239,0.095-0.504,0-0.743C30.819,15.355,29.112,11.215,25.23,8.184z M3.094,16.004  C3.914,14.27,7.842,7,16,7c2.383,0,4.387,0.615,6.068,1.517l-1.884,1.885C19.016,9.526,17.569,9,16,9c-3.859,0-7,3.14-7,7  c0,1.57,0.525,3.016,1.401,4.184l-2.197,2.198C5.2,20.127,3.589,17.075,3.094,16.004z M21,16c0,2.757-2.243,5-5,5  c-1.018,0-1.963-0.309-2.753-0.833l6.92-6.92C20.691,14.038,21,14.982,21,16z M11,16c0-2.757,2.243-5,5-5  c1.018,0,1.963,0.309,2.753,0.833l-6.92,6.92C11.309,17.962,11,17.018,11,16z M16,25c-2.383,0-4.387-0.615-6.068-1.517l1.884-1.885  C12.984,22.474,14.431,23,16,23c3.859,0,7-3.14,7-7c0-1.57-0.525-3.016-1.401-4.184l2.197-2.198c3.004,2.255,4.615,5.307,5.11,6.378  C28.086,17.73,24.158,25,16,25z" />
      </svg>
    </span>
  </div>

  <div class="form-control-group forgot-password" [routerLink]="['/auth/forgot-password']">
    Forgot Password?
  </div>

  <button nbButton fullWidth *ngIf="isLoading" type="button" nbButton status="primary" class="mr-3">
    <i class="fa fa-spinner fa-spin"></i>
  </button>
  <button nbButton fullWidth [disabled]="!dynamicForm.valid" *ngIf="!isLoading" type="submit" nbButton status="primary"
    class="mr-3">
    Log In
  </button>
</form>
