<h1 id="title" class="title">Create New Password</h1>
<p class="sub-title">Create a new password for your account</p>

<form [formGroup]="dynamicForm" (ngSubmit)="submitForm()" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="new-password">New Password:</label>
    <input nbInput fullWidth id="new-password" type="password" formControlName="new_password"
      placeholder="Enter new password" />
    <!-- Validation error messages for new password -->
    <div
      *ngIf="dynamicForm.get('new_password')?.errors && dynamicForm.get('new_password')?.touched" class="error-message text-danger mt-1">
      <small *ngIf="dynamicForm.get('new_password')?.errors?.required">Password is required.</small>
      <small *ngIf="dynamicForm.get('new_password')?.errors?.passwordLength">
        {{ dynamicForm.get('new_password')?.errors?.passwordLength }}
      </small>
      <small *ngIf="dynamicForm.get('new_password')?.errors?.passwordDigit">
        {{ dynamicForm.get('new_password')?.errors?.passwordDigit }}
      </small>
      <small *ngIf="dynamicForm.get('new_password')?.errors?.passwordAlphabet">
        {{ dynamicForm.get('new_password')?.errors?.passwordAlphabet }}
      </small>
      <small *ngIf="dynamicForm.get('new_password')?.errors?.passwordSpecialChar">
        {{ dynamicForm.get('new_password')?.errors?.passwordSpecialChar }}
      </small>
    </div>
  </div>

  <div class="form-control-group">
    <label class="label" for="confirm-password">Confirm Password:</label>
    <input nbInput fullWidth id="confirm-password" type="password" formControlName="confirm_password"
      placeholder="Confirm new password" />
    <!-- Validation error message for confirm password -->
    <div *ngIf="dynamicForm.errors?.passwordsMismatch && dynamicForm.get('confirm_password')?.touched"
      class="error-message text-danger mt-1">
      <small>{{ dynamicForm.errors.passwordsMismatch }}</small>
    </div>
  </div>

  <button nbButton fullWidth *ngIf="isLoading" type="button" nbButton status="primary" class="mr-3">
    <i class="fa fa-spinner fa-spin"></i>
  </button>
  <button nbButton fullWidth [disabled]="!dynamicForm.valid" *ngIf="!isLoading" type="submit" nbButton status="primary"
    class="mr-3">
    Create Password
  </button>
</form>
